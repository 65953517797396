.image-wall{
   height: auto;
   min-height: 300px;
   transform: translateY(-10%);

   li{
      list-style: none;
      display: block;
      height: 300px;
      background-size: cover;
      max-width: 80%;
      border-radius: 5px;
      margin: 45px 0;

      &:nth-child(2){
         height: 500px!important;

      }
   }
}

#about{
   margin-bottom: 105px;

   h1{
      padding: 25px;
   }

   p{
      margin-bottom: 25px;
      padding: 0 25px;

      .button{
         margin-top: 25px;
         display: block;
         text-align: center;
         max-width: 200px;
      }
   }

   .m-100{
      max-width: 100%;
      border-radius: 5px;
   }
}