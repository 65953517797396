$accent-color:    #F0393C;
$accent-second:   #006CB7;
$dark:            #3D3E42;
$light:           #ddd;
$lightest:        #fbfbfb;


.accent-color{
   background: $accent-color;
}
.accent{
   color: $accent-color;
}

.cw{
   color: white;
}

.accent-second{
   color: $accent-second;
}

.accent-dark{
   background: $dark;
}