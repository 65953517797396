footer{
   height: auto;
   min-height:480px;
   background: #fff;
   position: relative;
   display:flex;
   align-items:center;
   font-size: 18px;
   color: $dark;

   li{
      padding: 3px 0;
   }

   a{
      color: $dark;
   }

   h3{
      color: $accent-color;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 18px;
   }
   .accent-image{
      position: absolute;
      top: 0;
      max-height: 100%;
      right: 0;
      z-index: 0;
   }
}

.hours{
   max-width: 230px;
   span{
      float: right;
   }
}

.copyright{
   bottom: 5px;
   right: 8px;
   position: absolute;
   font-size: 12px;

   a{
      &:hover{
         color: $accent-color;
      }   
   }
   
}

