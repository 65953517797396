.bubbleBg{
   background-color: $accent-color;
   min-height: 250px;
   height: auto;
}

.main-header{
   min-height: 100vh;
   height:  auto;
   max-height: 800px;
   width: 100%;
   position: relative;

   .header-accent{
      height: 50vh;
      max-height: 400px;
      width: 100%;
      // position: absolute;
      left: 0;
      top: 0;
      background: $dark;
      z-index: -1;
   }
}


.no-header{
   margin-top: 35px;
   padding-top: 90px;
}
.title-header{
   height: 50vh;
   max-height: 300px;

   &.small{
      max-height: 300px;
   }

   h1{
      font-size: 80px;
      line-height: 80px;
   }

   span{
      color: $accent-second;
      font-size: 16px;
      font-weight: 400;
   }

   p{
      margin-top: 0px;
      margin-left: 7px;
   }
}

.text-header{
   position: absolute;
   transform: translateY(50%);
   left: 0;
   right: 0;
   margin: auto;
}

.p-header{
   font-size: 30px;
   color: $accent-second;
   margin-bottom: 45px;
}




.box{
	position: absolute;
	bottom: 45px;
	left: 50%;
	transform: translateX(-50%);
}

.box span{
	display: block;
	width: 12px;
	height: 12px;
	border-bottom: 2px solid $accent-color;
	border-right: 2px solid $accent-color;
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

.box span:nth-child(2)
{
	animation-delay: -0.2s;
}

.box span:nth-child(3)
{
	animation-delay: -0.4s;
}

@keyframes animate{
	
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}