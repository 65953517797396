.partner{
   img{
      max-width: 100%;
      transition: ease .3s transform;
   }

   &:hover{
      img{
         transform: scale(1.1);
      }
   }
}