@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;1,700&display=swap');

body{
   font-family: 'Noto Sans', sans-serif;
   height: 100%;
   margin: 0;
}

a{
   &:hover{
      text-decoration: none;
   }
}
.bold{
   font-weight: bold;
}
.no-bg-image{
   background-image: none!important;
}
.np{
   padding: 0;
   margin: 0;
}
.accentBg,
.bg-absolute{
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   width: 100%;
   background-image: url('../images/bubbleAccent.png');
   background-size: cover;


   &.center{
      background-position: center;
   }
   &.top{
      background-position: top;
   }
   &.bottom{
      background-position: bottom;
   }

   &.opacity-low{
      opacity: 0.2;
   }
}


.button{
   border: none;
   outline: 0;
   box-shadow: none;
   padding: 10px 25px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 4px;
   transition: ease .3s all;
}

.accent-button{
   
   &.dark{
      background: $dark;
      color: white;
   }
   &.accent{
      background:$accent-color;
      color: white;

   }
   &.accent-second{
      background-color: $accent-second;
      color: #fff;
   }

   &:hover{
      border-radius: 0px;
   }

   &.with-arrow{
      font-size: 20px;
      position: relative;
      padding: 15px 25px;
      img{
         margin-top: 5px;
         margin-right: 16px;
         z-index: 1;
         position: relative;
         transition: .3s margin ease-in-out;
         transition-delay: .2s;

      }

      .buttonSpan{
         position: absolute;
         top: 0;
         left: 0;
         width: 0;
         height: 100%;
         background:$accent-color;
         z-index: 0;
         transition: ease .3s all;
      }

      &:hover{
         .buttonSpan{
            width: 84px;
         }
         img{
            margin-right: 26px;
         }
      }
   }

}

.icon-list{
   margin-left: 20%;
   li{
      padding: 12px 0;
      font-weight: bold;
      font-size: 17px;
   }
   span{
      margin-right: 35px;
   }
}

#intro{
   h2{
      font-size: 48px;
      font-weight: bold;
      color: $accent-color;
      margin-bottom: 35px;
      // opacity: 0;
   }

   p{
      margin-bottom: 45px;
   }
   
}

#onderhoud{
   .offset{
      transform: translate(-10%, -30%);
   }
   h1{
      font-size: 64px;
      color: #fff;
      margin-bottom: 10px;
      max-width: 500px;
   }

   p{
      color: white;
      margin-bottom: 35px;
   }

   img{
      max-width: 440px;
   }

   .accent-button{
      font-weight: bold;
   }
}

.container-half-screen{
   height: 50vh;
   min-height: 500px;
}
.page{
   min-height: 70vh;
}
.page-enter{
   opacity: 0;
   // transform: translateY(-10%);
   // background: red;
   z-index: 99;
}

.page-enter-active{
   opacity: 1;
   transform: translateY(0);
   transition: all 400ms;
   transition-delay: 600ms;
   background: transparent;
}

.page-exit{
   opacity: 1;
}

.page-exit-active{
   opacity: 0;
   // transform: translateY(-10%);
   transition: all 400ms;
}

.fadeIn{
   opacity: 0;
}
.fadeInUp{
   opacity: 0;
}

.animate-in {
   display: inline-block;
   opacity: 0;
 }


 .padding-45{
    padding: 45px 0;
 }
 .padding-105{
    padding: 105px 0;
 }


 .logo-small{
    max-width: 150px;
    width: 60%;
 }


 .mb-offset{
    margin-bottom: 15%;
 }