.navToggler{
   display: none;
   height: 45px;
   padding: 7px 0 0 20px;
}


.navbar{
   min-height: 45px;
   background:$dark;

   .navToggler{
      &.active{
         span{
            &.first{
               margin-bottom: -6px;
               width: 38px;
            }
            &.second{
               width: 38px;
            }
            &.third{
               margin-top: -6px;
               width: 38px;
            }
         }
      }
      span{
         height: 3px;
         background: $accent-color;
         display: block;
         margin: 5px 0;
         width: 38px;
         transition: ease .3s all;

         &.third{
            width: 34px;
         }
         &.second{
            width: 30px;
         }
      }
   }

   .navigation{
      width: 100%;
   }

   ul{
      margin: 0;
      padding: 12px 0;
      transition: ease .3s all;
      li{
         float: left;
         list-style: none;
         font-size: 18px;
         a{
            color: white;
            transition: ease .8s color;
            &.current{
               color: $accent-color;
               font-weight: bold;;
            }
         }
      }
   }
}

.scrolled{
   .navbar{
      ul{
         padding: 5px 0;
      }
   }

   .logo{
      top: 15px;

      right: 75px;
      transition: ease .3s all;

      &.left{
            top: 7px;

         img{
            max-width: 40px;
         }
      }

      img{
         max-width: 140px;
      }
   }
}

.logo{
   top: 7px;
   position: absolute;
   right: 75px;
   transition: ease .3s all;

    &.left{
      left: 75px!important;
      max-width: 125px;

      img{
         max-width: 125px;
      }
   }


   img{
      max-width: 350px;
      transition: ease .3s all;

   }
}


.subnav{
   a{
      color: white;
      transition: ease .3s all;

      &:hover{
         color: $accent-color;
      }
   }
}