#realisaties{
   min-height: 400px;
   height: auto;
}


.thumbnail{
   padding: 25px;
}

.realisatie-thumbnail{
   width: 100%;
   height: 411px;
   border-radius: 66px;
   box-sizing: border-box;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
   border: 1px solid $light;
}


.realisaties{
   margin-bottom: 105px;
   transition: ease .3s all;
   margin-top: 45px;
   // transform: translateY(-6%);

   &:hover{
      .realisatie{
         filter: grayscale(100%);
         
         &:hover{
            filter: grayscale(0);
         }
      }
   }
   .realisatie{
      height: 100vh;
      max-height: 600px;
      background-size: cover;
      border-radius: 36px;
      position: relative;
      transition: ease .3s all;
      margin-bottom: 35px;

      &:hover{
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

         .realisatie-info{
            height: 120px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

            // min-height: 150px;
            
            .realisatie-info-inner{
               opacity: 1;
            }
         }  
      }

      .realisatie-info{
         position: absolute;
         bottom: -2px;
         left: 0;
         height: 0%;
         width: 100%;
         background: $lightest;
         transition: ease .3s all;
         border-radius: 0 0 36px 36px;

         .realisatie-info-inner{
            opacity: 0;
            pointer-events: none;
            padding: 25px;

            h2{
               line-height: 60px;
               font-size: 20px;
               text-align: center;
            }
         }
      }
   }
}
