.carousel{
   width: 80%;
   max-width: 1800px;
   // height: 100%;
   // max-height: 590px;
   // position: absolute;
   right: 0;
   left: 0;
   margin: auto;

   .carousel-inner{
      height: auto;
      max-height: 590px;
      width: 100%;
      // position: absolute;
   }
   .carousel-item{
      height: 100%;

      img{

      }
   }
   .carousel-indicators{
      top: 0;
      bottom: 100%;
   }

   .carousel-control-next,
   .carousel-control-prev{
      // transform: translateY(-15%);
   }
}

.carousel-banner{
   text-align: center;
   background: $dark;
   color: white;
   width: 80%;
   max-width: 1800px;
   margin: 0 auto;
   padding: 15px 25px;
}

.slider-container{
   position: relative;
   width: 100%;
   height: 100%;
   max-height: 590px;
   transform: translateY(-200px);
}