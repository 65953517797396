.skew-background{
   background: $lightest;
   padding: 85px 0 0px 0;
   transform: skew(0deg, -5deg) translateY(13%);

   .skew-content{
      transform: skew(0deg, 5deg);
   }
}



#contact{
   form{
      transform: translateY(-8%);
      padding: 45px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
      label{
         color: $dark;
      }
      input,
      textarea,
      select{
         background: none;
         outline: none;
         box-shadow: none;
      }
   }
   // h1{
   //    font-weight: bold;
   //    font-size: 85px;
   // }
}

.contact-banner-small{
   background: $accent-color;
   height: 20vh;
   min-height: 300px;
   max-height: 320px;

   color: #fff;

   span{
      opacity: 0.8;
   }

   h2{
      font-weight: bold;
      font-size: 45px;
   }
}


.inputError{
   border: 1px solid $accent-color;

   &:focus{
      border: 1px solid $accent-color;
   }
}

.inputValid{
   border: 1px solid green;
}

.map{
   width: 100%;
   height: 450px;
   position: relative;

   .maps{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
   }
}

ul{
   .spacer{
      height: 15px;
   }
}

.message{
   margin-top: 25px;
}

.smaller{
   font-size: 12px;
   margin-top: 15px;
   display: block;
   line-height: 14px;
}