@media screen and(max-width: 1500px){
   .logo{
      &.left{
         display: none;
      }
   }
}

@media screen and(max-width: 1300px){
   #onderhoud{
      h1{
         font-size: 60px;
      }
   }
}

@media screen and(max-width: 1232px){
   .carousel-banner{
      bottom: -90px;
   }
}

@media screen and(max-width:1200px){
   .navToggler{
      display: block;
   }

   #contact{
     form{
        transform: translateY(-2%);
        margin: 3rem 0;
     }
   }

   .navigation{
      height: 0;
      background: $dark;
      transition: ease .3s all;
      pointer-events: none;

      .navbarList{
         margin: 0;
         padding: 0;
         opacity: 0;
         transition: ease .3s opacity;
         li{
            float: none;
            width: 100%;

            a{
               font-size: 25px;
            }
         }
      }
      &.active{
         height: 100vh;
      pointer-events: all;


         .navbarList{
            opacity: 1;
         }
      }
   }
    #onderhoud{
         h1{
            font-size: 40px;
         }

         .offset{
            transform: translate(0,0);
            max-width: 100%;
         }
      }

      .subnav{
         text-align: center;
      }
   
}

@media screen and(max-width: 1000px){
   .realisatie-img{
         flex: 0 0 50%;
         max-width: 50%;
   }
   
   .realisatie-btn{
      flex: 0 0 100%;
      max-width: 100%;
      height: 80px;
      text-align: center;
   }

   .slider-container{

      .carousel{
         width: 100%;
      }

      .carousel-banner{
         width: 100%;
         bottom: -70px;
      }
   }

   #realisaties{
      .col-md-2{
         display: none;
      }
   }
}

@media screen and(max-width: 992px){
   .a-img{
      display: none;
   }
}

@media screen and(max-width:980px){
   .slider-container{
      // max-height: 500px;
   }
}

@media screen and(max-width:900px){
   .logo{
      img{
         max-width: 200px;
      }
   } 

   #onderhoud{
      h1{
         font-size: 30px;
      }
   }

   .title-header{
      text-align: center;
   }

}

@media screen and(max-width: 832px){

   .main-header{
      height: 80vh;
   }
   .slider-container{
      max-height: 450px;

      .carousel{
         width: 100%;
      }

      .carousel-banner{
         width: 100%;
      }
   }


   .box{
      display: none;
   }
}

@media screen and(max-width: 800px){
   .title-header{
      
      h1{
         font-size: 55px;
      }
   }
   .slider-container{
      .carousel-banner{
         width: 100%;
         bottom: 10px;
      }
   }
}


@media screen and(max-width: 780px){
   .header-intro{
      padding-top: 80px;
   }
   .subnav{
      // display: none;
      .d-inline{
         display: block!important;
      }
   }
}

@media screen and(max-width: 468px){
   a{

      &.button{
         display: block;
      }
   }
}

@media screen and(max-width: 428px){
  h1{
     &.accent{
        font-size: 40px;
     }
  }
}